<template>
  <div class="home">
    <Header/>

 <div class="content">  
     <div class="actives fadeInDow">  
        <van-swipe
        class="my-swipe1"
        :autoplay="3000"
        :show-indicators="false"      >
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img :src="dqyy =='zh-CN'? item.thumb:item.thumb_en" alt="" class="imgss" />
        </van-swipe-item>
      </van-swipe>  
      <div class="notific">  
        <img src="../assets/img/ns.png" class="ico" alt="" srcset="">
        <div class="right">
        <van-notice-bar
        v-if="notice"
  background="transparent"
  :scrollable="false"
  color="#ffffff"
  :text="dqyy =='zh-CN'?notice.title:notice.titleEn"
  @click="toNotice()"
/>   </div>
      </div>      
      </div>

 <div class="card">
  <div class="titles" @click="getInfo()">{{$t('m.wdzc')}}</div>
  <div class="box">
     <div class="cont">
      <div class="ibox">
          <div class="rbox">
            <div class="num">{{userInfo.daAmount||0}}</div>
            <div class="txt">DA</div>           
          </div>
      </div>
     </div>
      <div class="buttom">
        <van-button
          type="primary"
          class="btn act"
          @click="toRech()"
        >
        {{$t('m.cz')}}</van-button
        >
         <van-button
          type="primary"
          class="btn act"
          @click="toWithdraw()"
        >
        {{$t('m.tx')}}</van-button
        >
     </div>
     <div class="buttom">
        <van-button
          type="primary"
          class="btns"
          @click="handleFiexdToolItem('/qbdetails')"
        >
        {{$t('m.czjl')}}</van-button
        >
         <van-button
          type="primary"
          class="btns"
          @click="handleFiexdToolItem('/qbdetail')"
        >
        {{$t('m.txjl')}}</van-button
        >
     </div>
  </div>
 

 </div>
  <div class=" nocard">   
      <div class="box">             
          <div class="cont" >         
         <div class="ibox">       
           <div class="titles">BNB/DA-LP</div>
          <div class="txts a" @click="getInfo">
            <span class="a1"></span>
            <span class="a2"></span>
            <span class="a3"></span>
            <span class="a4"></span>
            <span class="num"> {{userInfo.lpAmount||0}}</span> Cake-LP
            ({{lv}}%)
            </div>

          <div class="line"></div>
        <div class="item fadeInDow" @click="getTotal">
          <div class="left">{{$t('m.qwkpsp')}}</div>
          <div class="rbox">
            <div class="num">{{(total.stat8+174878).toFixed(0)||0}}</div>
            <div class="txt">{{$t('m.zhang')}}</div>           
          </div>         
          </div>
         <div class="line"></div>
        <div class="item fadeInDow" @click="getTotal">
          <div class="left">{{$t('m.qwxhzz')}}</div>
          <div class="rbox">
            <div class="num">{{(total.stat3+874.39).toFixed(2)||0}}</div>
            <div class="txt">DA</div>           
          </div>         
          </div>
             <div class="line"></div>
        <div class="item fadeInDow" @click="getTotal">
          <div class="left">{{$t('m.qwstjszz')}}</div>
          <div class="rbox">
            <div class="num">{{(total.stat4+1918.97).toFixed(2) ||0}}</div>
            <div class="txt">DA</div>           
          </div>         
          </div>

               <div class="line"></div>
        <div class="item fadeInDow" @click="getTotal">
          <div class="left">{{$t('m.qwdhkpxh')}}</div>
          <div class="rbox">
            <div class="num">{{(total.stat9+79920).toFixed(0)||0}}</div>
            <div class="txt">{{$t('m.zhang')}}</div>           
          </div>         
          </div>
          

         </div>
        </div>  

           
      </div>  
     </div> 


     <div class="mtop">
      <div class="title">{{$t('m.dappname')}}</div>
     </div>
    
<!-- 添加流动性 -->
<div class="card">
      <div class="titles" >{{$t('m.hdqy')}}</div>
      <div class="tab">
        <div class="tabitem"  :class="actnum==0.1?'act':''" @click="toActNums(0.1)">0.1DA</div>
        <div class="tabitem" :class="actnum==0.3?'act':''" @click="toActNums(0.3)">0.3DA</div>
        <div class="tabitem" :class="actnum==0.5?'act':''" @click="toActNums(0.5)">0.5DA</div>
      </div>
      <div class="bitem"  v-for="(item,i) in list" :key="i">    
        <div class="box" v-if="actnum==item.joinAmount">
          <div class="cont"  >         
         <div class="ibox">
          
          <div class="rbox">
            <div class="num">{{dqyy=='zh-CN'?item.name:item.nameEn}}</div>
            <div class="txt"></div>           
          </div>
          <div class="txts a">
            <span class="a1"></span>
            <span class="a2"></span>
            <span class="a3"></span>
            <span class="a4"></span>
            {{$t('m.djkpsp')}}<span class="num"> {{item.info?item.info.wr2:0}}</span> {{$t('m.zhang')}}</div>
          <div class="time">{{$t('m.cysj')}}{{item.startTime+':00-'+item.endTime+":00"}}</div>
         </div>
       
        </div>
        <div class="item fadeInDow">
        <div class="cont m10 ">
          <div class="icos"><div class="txt">{{$t('m.dqxhz')}}</div></div>
          <div class="rbox">
            <div class="num">{{item.r2||0}}</div>
              <div class="txt">DA</div>           
          </div>
        </div>            
        <div class="line"></div>
         <div class="cont m10 ">        
          <div class="icos"> <div class="txt">{{$t('m.stjsz')}}</div></div>
          <div class="rbox">
            <div class="num">{{item.r3||0}}</div>
              <div class="txt">DA </div>            
          </div>
        </div>   
         
            <div class="buttom">
        <van-button
          type="primary"
          class="btn act"
          @click="toPage(item)"
        >
        {{$t('m.jrqy')}}</van-button
        >
      </div>
      </div>
      </div>
  </div> 
     </div>  
    

      
    <div class="buttoms">Copyright © 2024 DA. All rights reserved.</div>

      <!-- <div class="flexbottom fadeInUp" @click="handleFiexdToolItem('index')">
       <div class="txt"> DA</div>
       <div class="txt">DA MEET YOU</div>
      </div> -->
    </div>


   

<!-- 授权 -->
 <van-popup v-model="impower" round :close-on-click-overlay="false">
      <div class="popup">       
        <div class="sqtxt">{{$t('m.sqinfo')}}</div>
        <div class="flsz">       
            <van-button
          :loading="sqloading"
          type="primary"
          @click="approve"
          class="btn"
           :loading-text="$t('m.sqload')"
        >
       {{ $t("m.sqbtn") }}</van-button
        >
         <!-- <div class="btn" @click="approve">
          {{ sqloading ? $t("m.sqload") : $t("m.sqbtn") }}
        </div> -->
        </div>
      </div>
    </van-popup>
    
<!-- 充值 -->
 <van-popup v-model="lqshow" round :close-on-click-overlay="false">
      <div class="popup">
           <div class="top"> <div class="title">{{$t('m.czqr')}} </div>
        <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshow=false;lqloading=false"/>
    </div>
        <div class="sqtxt">{{$t('m.ny')}}</div>
          <div class="rbox">
            <div class="num">{{myDa||0}}</div>
            <div class="txt">DA</div>
            <img src="../assets/img/logo.png" alt="" srcset="" class="ico">
          </div> 
          <div class="sinput">
            <div class="laber">{{$t('m.sl')}}</div>
            <input type="text" :placeholder="$t('m.srsl')" class="input" v-model="toNum" @input="max" :readonly="lqloading">
          </div>
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="topUp"
          class="btn"
        >
        {{$t('m.cz')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <!-- 领取余额 -->
    <van-popup v-model="lqshows" round :close-on-click-overlay="false">
      <div class="popup">
           <div class="top"> <div class="title">{{$t('m.txqr')}}</div>
        <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshows=false;lqloading=false"/>
    </div>
        <div class="sqtxt">{{$t('m.ny')}}</div>
          <div class="rbox">
            <div class="num">{{lqNum||0}}</div>
            <div class="txt">DA</div>
            <img src="../assets/img/logo.png" alt="" srcset="" class="ico">
          </div> 
          <div class="sinput">
            <div class="laber">{{$t('m.sl')}}</div>
            <input type="text" :placeholder="$t('m.srsl')" class="input" v-model="toNums" @input="maxs" :readonly="lqloading">
          </div>
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="toPay"
          class="btn"
        >
        {{$t('m.tx')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="shows" round :close-on-click-overlay="false">
      <div class="popup">
        <div class="top"> 
        <div class="title">{{ $t("m.dltt") }}</div></div>
        <div class="txt">{{ $t("m.dlinfo") }}</div>
        <div class="btns" @click="ethConnect">{{ $t("m.dlbtn") }}</div>
        <div class="lang"  @click="changer(dqyy)">
          <img src="../assets/img/cny.png" alt="" />
          <span>{{ $t("m.xzlang") }}</span>
        </div>
      </div>
    </van-popup>

 <van-popup v-model="bdshows" round :close-on-click-overlay="!bdshows">
      <div class="popup">
             <div class="top"> 
        <div class="title">{{ $t("m.bdtt") }}</div></div>
        <div class="txt">{{ $t("m.bdinfo") }}</div>
        <div>
          <input type="text" class="input" v-model="yqmcode" />
        </div>
        <van-button
          :loading="bdloading"
          type="primary"
          @click="tobding"
          class="btn"
          :loading-text="$t('m.bdload')"
        >
          {{ $t("m.bdbtn") }}</van-button
        >
      </div>
    </van-popup>

 
  </div>
</template>

<script>
import { connectTron ,getRegions,getUser,getMyUsdt,getLuck,transferDA, getDA,transferHt,Sign} from "@/api/Public";
import Header from '../components/header.vue';
import {createWallet} from "@/api/api"
export default {
  name: "Home",
  components: {Header},
  data() {
    return {
      codeWallet:"",
      notice:"",
      sxshow:false,
      xzNum:'',
      bdshows: false,
      shows: false,
      impower: false,
      userWallet: "",
      yqmcode: "",
      Time: "",
      sqloading: false,
      bdloading: false,
      userInfo:"",
      endTime:0,
      lqshow:false,
      lqshows:false,
      lqloading:false,
        banner: [ 
        {  "thumb": require('../assets/img/banner.jpg'),"thumb_en": require('../assets/img/banners.jpg')},
        {  "thumb": require('../assets/img/banner1.jpg'),"thumb_en": require('../assets/img/banner1.jpg')},
        {  "thumb": require('../assets/img/banner2.jpg'),"thumb_en": require('../assets/img/banner2s.jpg')},

      ],   
        list:[],
        time:'',
        exshow:false,
        exshows:false,
        exloading:false,
        exmcode:'',
        values:0,
        exnum:'',
        twAmount:'',        
        sqNum:0,
        sqNums:0,
        sqUsdtNum:0,
        sqArcNum:0,
        actnum:0.1,
        lqType:0,
        pool:0,
        mylp:"",
        stakeAmount:0,
        luckyReward:0,
        myDa:0,
        toNum:"",
        lqNum:0,
        total:0,
        toNums:'',
        lv:0


    };
  },
  filters: {
      Endtime(e){
    var    end_str = (e).replace(/-/g,"/");//一般得到的时间的格式都是：yyyy-MM-dd hh24:mi:ss，所以我就用了这个做例子，是/的格式，就不用replace了。  
    var end_date =new Date(end_str)-new Date;//将字符串转化为时间  
    return end_date
    },
   
    formatDate(e) {
      if (e == 0) {
        return "";
      } else {
        let date = new Date(e * 1000);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      }
    },
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{7})\w+(\w{4})/, "$1...$2");       
        return a;
      } else {
        return "...";
      }
    },
      time(time){
      if(time){
        var reg = /^(\d{4})-(\d{1,2})-(\d{1,2})\s(\d{1,2})(:(\d{1,2})){2}$/;
        var end = time.match(reg).slice(1,4).join('-');
        return end
      }
    }
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.pustadd(val)
    },
     "$store.getters.nlist": function (val) {
      //  console.log(val.length);
       this.mapdata()
    },
    deep: true,
    immediate: true,
  },
  // beforeCreate(){
  //   this.ethConnect()
  // },
  beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
      if (typeof window.ethereum !== "undefined") {
        connectTron().then(
          (info) => {
            clearInterval(a);
            if (info[0].indexOf("0x") == 0) {
              if(this.$store.getters.myFil!=info[0]){  
                this.userWallet =  info[0]     
                this.impower=true  
                this.$store.commit("userWallet", ""); 
                this.$store.commit("myID", ""); 
                // this.toCread(info[0])                            
                // this.$store.commit("userWallet", info[0]); 
                // this.getUsers(info[0])
                // this.yanzheng(info[0])
              }else{
                this.$store.commit("userWallet", info[0]); 
                this.toCread(info[0])                  
              }
                 
                   
              
            } else {
              this.$notify("请使用HECO地址登录游戏");
            }
          }
        );
      }
      if (i > 5) {
        this.$notify("钱包失联了！！");
        clearInterval(a);
      }
      console.log(i);
      i++;
    }, 1000);
  },

  created() { 
    this.userWallet = this.$store.getters.userWallet;      
    this.getNotice()    
    this.getTotal()   
  },

  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  methods:{
    toActNums(e){
      this.actnum=e
    },
    async getTotal(){
          let {data: { code, data, message },} = await this.$http.get(this.$api.getTotal,{});
          if(code==200){
            this.total=data
          }     
    },
    async getBase(){
        let {data: { code, data, message },} = await this.$http.get(this.$api.getBase);
      if(code==200){
        if(data.totals!=0){
       let num =(this.userInfo.lpAmount/data.totals*100)
        this.lv =Math.floor(num * 1000) / 1000;
        }
      }
    },
    async getList(){
      let {data: { code, data, message },} = await this.$http.get(this.$api.getRegionList, {
        pageNum : 1,     
        pageSize :500  
      });
      if(code==200){
        this.list=data
        this.list.map((el,i)=>{
            this.getInfos(el.id,i)
          })
      }
    },
    async getInfos(rid,i){    
    let {data: { code, data, message },} = await this.$http.get(this.$api.getUserRegion,{
      rid:rid,
      uid:this.userInfo.id
    });
      if(code==200){
        if(data){this.$set(this.list[i],'info',data)}
          
      }
    },
    async toCread(address){
       let {data: { code, data, message },} = await this.$http.post(this.$api.createWallet, {
       trxAddress: address,        
      });
      if(code==200){
        this.userInfo=data
        this.getBase()
        this.$store.commit("myID", data.id); 
        this.getList()  
      }
    },
    async getInfo(){
       let {data: { code, data, message },} = await this.$http.get(this.$api.getWallet, {
       trxAddress: this.$store.getters.userWallet,        
      });
      if(code==200){
        this.userInfo=data
        this.getBase()
        this.$store.commit("myID", data.id); 
        this.getList()  
      }
    },

    toRech(){      
      if(this.userInfo.id){
      getDA(this.$store.getters.userWallet).then(res=>{        
        this.myDa=(res*1).toFixed(4)
        this.lqshow=true
      })
    }else{
      this.getInfo()
    }
    },
    max(){
      setTimeout(() => {
        this.toNum=this.toNum-this.myDa>0?this.myDa:this.toNum
      }, 100);      
    },
    topUp(){
      if(!this.userInfo.id){return }
      if(this.toNum>0){
        this.lqloading=true
        transferDA(this.$store.getters.userWallet,this.toNum).then(res=>{
          console.log(res);
          // res="ceshihaxi1234"
            if(res){
                this.updata(res)
            }else{
              this.lqloading=false
              this.$notify({
                message: this.$i18n.t("m.czsb"),
                type: "danger",
              });
            }
        })
      }
    },
    async updata(rxid){
      let {data: { code, data, message },} = await this.$http.post(this.$api.recharge, {
        amount:this.toNum,
        trxId:rxid,
        uid:this.userInfo.id,
       trxAddress: this.$store.getters.userWallet,        
      });
      if(data){
        this.$notify({message: this.$i18n.t("m.czcg"),type: "success",});
        this.lqloading=false
        this.lqshow=false
        this.getInfo()
      }else{
        this.lqloading=false
        this.$notify({
                message: this.$i18n.t("m.czsb"),
                type: "danger",
              });
      }
    },

    toWithdraw(){
      this.lqshows=true
      this.lqNum=this.userInfo.daAmount
    },
    maxs(){
      setTimeout(() => {
        this.toNums=this.toNums-this.lqNum>0?this.lqNum:this.toNums
      }, 100);
    },
    async toPay(){
      if(this.toNums>0){
      let {data: { code, data, message },} = await this.$http.get(this.$api.getBase);
      if(code==200){
        if(data.transferState==0){
            transferHt(this.$store.getters.userWallet,0.0005).then(res=>{
      if(res){
        this.lqloading=true
         this.toReward()
      }
    })
        
        }else{
          this.$notify({message:this.$i18n.t("m.txzwkf"),type: "danger",});
        }
      }
    }
    },

  async toReward(){  
    let {data: { code, data, message },} = await this.$http.get(this.$api.getReward,{
      amount:this.toNums,type:1,uid:this.$store.getters.myID
    });
    if(code==200){
      this.$notify({message: this.$i18n.t("m.txcg"),type: "success",});
          this.lqloading=false
          this.lqshows=false
          this.getInfo()
    }else{
      this.lqloading=false
      this.$notify({message: this.$i18n.t("m.txsb") ,type: "danger",});
    }
  },

  async toPage(val) {   
      sessionStorage.setItem('key',JSON.stringify(val)) 
     if(this.$store.getters.userWallet){
       this.$router.push({ path: '/index?id='+val.id });
      }else{
        this.shows=true
      }
    },


  async getNotice(){
    let {data: { code, data, message },} = await this.$http.get(this.$api.getNewsList,{
        pageNum : 1,
          pageSize :1,
      });
    if(code==200){
      this.notice=data[0]
    }
  },
  toNotice(){
    this.$router.push({ path: '/nDetail?id='+this.notice.id });
  },









    getNuma(){
      getMyUsdt().then(res=>{
        this.stakeAmount=res
        console.log(res);
      })
       getLuck().then(res=>{
        this.luckyReward=res
        console.log(res);
      })
    },
    getUsers(){
        getUser(this.$store.getters.userWallet).then(res=>{
          console.log(res);
        this.userInfo=Object.assign({},res)               
        // this.userInfo.luckyPoints=(res.luckyPoints/10**18).toFixed(4)
        // this.userInfo.nftPoints=(res.nftPoints/10**18).toFixed(4)         
      })
    },
    getLists(){
      console.log(1);
      // this.list=[{
      //       id:0,
      //       name:0.1,
      //       luckyReward:0.1,
      //       stakeAmount:10
      //     }]
      getRegions().then(res=>{
        // console.log(res,getRegions);
        if(res){
            this.list=[]
          // res.map((el,i)=>{
            let obj=Object.assign({},res)
            obj.id=0
            obj.name=(res[0])
            obj.stakeAmount=(res[1]/10**18)
            obj.luckyReward=(res[2]/10**18)           
            this.list.push(obj)
          // })
          this.$forceUpdate()
        }
      })
    },
 toshow(e,j){
      this.$set(this.list[e],'show',!j)
    },





    togetMyDeedPool(){
      getMyDeedPool().then(res=>{
        this.pool=(res*1).toFixed(4)      })
    },
    toLqShow(num,t){
      this.lqNum=num
      this.lqType=t
      this.lqshow=true
    },
    
    
    onSelect(e){
      this.xzNum=e.text
      this.sxshow=false
    },
    async toJoin(){
      if(this.xzNum>0){ 
        this.loading=true
        this.sqUsdtNum=((this.xzNum)*0.3/2).toFixed(4)
       await this.toGetsq()
        getUtoArc(this.sqUsdtNum).then(res=>{
          console.log(res);
          this.sqArcNum =(res*1).toFixed(4)
          this.lqshows=true         
        })
      }
    },
    async toGetsq(){
      this.sqNum=await getProved(this.userWallet).then(res=>{ return res})
      this.sqNums=await getProveds(this.userWallet).then(res=>{ return res})
      console.log(this.sqNum,this.sqNums);
    },
   async scSelects(){
       await this.toGetsq()
       if(this.xzNum<=0){return}
       if(!this.userWallet){return}
      this.lqloading=true
      console.log(this.sqUsdtNum -this.sqNum>0);
      if(this.sqUsdtNum-this.sqNum>0){
        toApprove(this.userWallet,this.sqUsdtNum).then(res=>{
            // console.log(res);
            if(res){   
              this.sqNum=this.sqUsdtNum 
              this.scSelects()
            }else{
              this.lqloading=false
            }
        })
      }else if(this.sqArcNum -this.sqNums>0){
        let num=Math.ceil(this.sqArcNum)
        toApproves(this.userWallet,num).then(res=>{
            // console.log(res);
            if(res){    
             this.sqNums= this.sqArcNum 
              this.scSelects() 
            }else{
                this.lqloading=false
              }
        })
      }else {
        toBookOrder(this.userWallet,this.xzNum).then(res=>{
            console.log(res);
            this.lqloading=false
            if(res){
              this.lqshows=false
              this.loading=false
              this.$notify({
                message: this.$i18n.t("m.yycg"),
                type: "success",
              });
            }else{
              this.$notify({
                message: this.$i18n.t("m.yysb"),
                type: "danger",
              });
            }
          })
      }
  },
    toexchange(){
      this.exnum=''
      this.exshow=true
    },
    jstwAmount(){
      setTimeout(() => {      
      this.twAmount=this.userInfo.usdtAmount-this.twAmount>0?this.twAmount:this.userInfo.usdtAmount     
      }, 100);
    },
    toexchanges(){
      if(this.exnum>0){
             this.$post({
        url: "wms-wallet/exchange",
         data:{
         trxAddress:this.$store.getters.userWallet,
         uid:this.$store.getters.myID,
         amountT:this.exnum,
         amountU:(this.exnum*this.price).toFixed(4),
         rate:this.price,
         type:1
         },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.exshow=false
              this.cread()
                  this.$notify({
                message: this.$i18n.t("m.sdsuc"),
                type: "success",
              });
              }else{
                this.$notify(this.$i18n.t("m.sderr"))
              }
          })
        }
       })
      }
    },
   
 

  
handleFiexdToolItem(e){
    // this.$notify(this.$i18n.t("m.wxtszwkf"))
    // return
  this.$router.push({path:e})
},
 onChange(index) {
      this.current = index;
    },
toimpower(){
  this.$notify(this.$i18n.t("m.wxtszwkf"))
  //  if(this.$store.getters.userWallet){
  //     this.impower=true
  //     }else{
  //       this.shows=true
  //     }
},
approve(){
  if(this.userWallet){
    this.sqloading=true
     Sign(this.userWallet).then(rs=>{
       this.sqloading=false
      if(rs){          
        this.impower=false
        this.$store.commit("myFil", this.userWallet); 
        this.toCread(this.userWallet)                            
        this.$store.commit("userWallet", this.userWallet);   
       }
      })
  }
},


      lqselects(e){
          this.$notify(this.$i18n.t("m.zwkf"))
          return     
    },

  async scSelect(){
    if(this.lqNum<=0){return}
    this.lqloading=true
    if(this.lqType==1){
      toWithdrawalStatic(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
          this.upUser()
          this.lqshow=false
          this.$notify({
                message:this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })      
    }
    if(this.lqType==2){
      toWithdrawalDynamic(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
          this.upUser()
          this.lqshow=false
           this.$notify({
                message: this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })
    }  
    },


    to(url) {
       window.open(url)
    },
   
  

    getLp(){
      this.mylp=sessionStorage.getItem('mylp')?JSON.parse(sessionStorage.getItem('mylp')):""
      getMyLp(this.userWallet).then(res=>{
          this.mylp=res
          sessionStorage.setItem('mylp',JSON.stringify(res))
        })
        getTime1().then(res=>{
          this.time=res
          console.log(res,'time');})
    },
    cread(val) {
      this.userWallet = this.$store.getters.userWallet||val;  
      if(this.userWallet){               
        // getHoder().then(res=>{console.log(res,'Hoder');})
        // console.log(this.userWallet);
        getUser(this.userWallet).then(res=>{
          // console.log(res);          
          this.userInfo=Object.assign({},res)
          this.userInfo.totalInvestment=(res.totalInvestment/10**18).toFixed(4)
          this.userInfo.staticReward=(res.staticReward/10**18).toFixed(4)
           this.userInfo.dynamicReward=(res.dynamicReward/10**18).toFixed(4)
             this.userInfo.runInvestment=res.runInvestment/10**18
             this.getLp()
           let time= new Date().getTime()
          this.endTime=((res.lastBookingTime*1000+this.time*1000)-time)        
          // res.lastBookingTime
          if(res.referrer=="0x0000000000000000000000000000000000000000"){
            this.bdshows = true;
          }
        })
      }
    },
    upUser(){
         getUser(this.userWallet).then(res=>{
          this.userInfo=Object.assign({},res)
          this.userInfo.totalInvestment=(res.totalInvestment/10**18).toFixed(4)
          this.userInfo.staticReward=(res.staticReward/10**18).toFixed(4)
           this.userInfo.dynamicReward=(res.dynamicReward/10**18).toFixed(4)    
           this.getLp()   
        })
    },
 

    

    zwkf(){
         this.$notify(this.$i18n.t("m.zwkf"))
    },
 


   
    toshowasd() {
      if (this.$store.getters.theame) {
        if (this.$store.getters.theame == 1) {
          this.acdite = true;
        } else {
          this.acdite = false;
        }
      } else {
        this.acdite = false;
      }
      console.log(this.acdite);
      window.document.documentElement.setAttribute(
        "data-theme",
        this.acdite ? "dark" : "light"
      );
    },
    toggleTheme() {
      this.acdite = !this.acdite;
      window.document.documentElement.setAttribute(
        "data-theme",
        this.acdite ? "dark" : "light"
      );
      this.acdite
        ? this.$store.commit("theame", "1")
        : this.$store.commit("theame", "0");
    },
    tobding() {
      if (this.yqmcode) {
        if (this.bdloading) {
          this.$toast(this.$i18n.t("m.bdload"));
          return;
        } else {
          getCanBind(this.yqmcode).then(res=>{
            if(res){
            this.bdloading = true;
          console.log(this.yqmcode);
          // this.$toast(this.yqmcode);
          this.bangding(this.yqmcode);
            }else{
              // this.$toast(this.$i18n.t("m.dqzdbmz"));
              this.$notify(this.$i18n.t("m.dqzdbmz"))
            }
          })
        
        }
      } else {
        this.$toast(this.$i18n.t("m.bdinfo"));
      }
    },
 
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    onCopy() {
      this.$toast(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
    //总投资
    async joinAmount() {
      this.joinamount = await window.ContractEth.methods.joinAmount().call();
    },
    
    ethConnect() {
      connectTron().then((info) => {
        if (info[0].indexOf("0x") == 0) {          
          this.$store.commit("userWallet", info[0]); 
        } else {
          this.$toast(this.$i18n.t("m.qsydzdl"));
        }
      });
    },
    pustadd(info){     
      this.$store.commit("userWallet", info); 
    },


    async bangding(code) {
       
        if (code) {
          if(code==this.userWallet){return}
          toBind(this.userWallet,code).then(res=>{
            this.bdloading = false;
            if(res){
              this.$toast(this.$i18n.t("m.bangsuc"));
              this.bdshows = false;
            }
            console.log(res);
             
          })      
        } else {
          this.bdloading = false;
        }      
    },
  
    igbs() {
      this.show = false;
    },
    tosee() {
      this.more = !this.more;
    },
   
  
    goto(val,e) {   
       clearInterval(this.time);   
       if(e){
        
       this.$router.push({ path: val,query:{ac:e} }); 
       }else{
      this.$router.push({ path: val });
       }

    },
  
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;

  .sylist{
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: space-between;
    .boxl{
      background: linear-gradient(-18deg, #ffdf2d, #00e2be);;
      // border-radius: 18px;
      height: 140px;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;  
    }
    .boxr{
      background:rgba(38, 38, 38, 0.48);//#262626 ;
      // border-radius: 18px;
      height: 140px;
      width: 45%; display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; 
      position: relative;   
    }
    .stl{
      margin: 10px 30px;
      color: #ffffff;
      font-size: 22px;
    }
    .snum{
        margin: 10px 30px;
      color: #ffffff;
      font-size: 26px;
    }
    .flximg{
      position: absolute;
      right: 20px;
      width: 130px;
    }
  }
  
  .mdex{
    font-size: 24px;
    color: #fff;
    background: rgba(0 0 0 / 24%);
    margin: 0 0 20px;
    padding: 20px;
    border-top:1px solid  #6d6d6d
  }
  .my-swipe {
    width: 694px;
    min-height: 360px;
    margin: 10px auto 0;
    .imgs {
      width: 100%;
      height: 100%;
      // border-radius: 20px;
    }
  }
  
  .band {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    height: 199px;
    width: 199px;
    margin: 10px auto;
    animation: rotate 10s linear 0.1s infinite;
    img {
      height: 38px;
      width: 246px;
      margin: 64px auto;
    }
  }

  @keyframes rotate {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }

    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }

  .content {
  //  background: url("../assets/img/bm/b6.png") ;
    background-size: 100% auto;
    background-position: bottom ;
    margin-top: 160px;
    
    .actives {
      // padding: 56px 0;
    background: #00000070;
    // border-radius: 20px;
    // background-size: cover;
        // border-radius: 30px;
    width: calc( 100%);
    margin: 0 auto;
    .my-swipe1 {
    width: calc( 100% - 20px);
    height: 100%;
    margin: 0 auto;
    // padding: 10px; 
     .imgss {
      width: 690px;
      height: auto;
        //  border:2px solid #3bf7ff;
    border-radius: 16px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 12%), 0 8px 8px rgb(0 0 0 / 12%), 0 16px 16px rgb(0 0 0 / 12%);
    }
    }
    .notific{
      background: #000000;
      border: 1px solid #735f24;
      display: flex;
      align-items: center;
      width: calc(100% - 60px);
      padding: 14px 20px;
      margin: 6px auto;
      border-radius: 56px;
      box-sizing: border-box;
    z-index: 10;
    position: relative;
      .ico{
        width: 46px;
        height: 46px;
      }
      .txt{
        font-size: 26px;
        color: #ffffff;
        margin-left: 16px;
        margin-bottom: 5px;
      }
      .right{
        width:calc(100% - 40px);
        font-size: 28px !important;
      }
    }
    .simg{
      width: 256px;
      height: 256px;
      margin: 20px auto 0;
      // border-radius: 10px;
    }    
      .info {
        width: 600px;
        min-height: 52px;
        font-size: 24px;
        font-weight: 400;
        @include font_color("font_color2");
        line-height: 52px;
        margin: 12px auto 33px;
        text-align: left;
      }
     
      .btngrop{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn {
        width: calc(100% - 40px);
        height: 80px;
        background: #b4184c;
        box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
        // border-radius: 40px;
        font-size: 32px;
        font-family: $t-mf;
        margin:34px 20px;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 80px;
        text-align: center;        
border: 2px solid rgba(115, 40, 236, 0.5);
font-weight: bold;
      }
      .act{
        background: #290E54;
box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
// border-radius: 40px;
border: 2px solid rgba(115, 40, 236, 0.5);
      }
    }
    .mtop{
      display: flex;
      align-items: center;
      .title{
      font-size: 38px;
      font-weight: bold;
      color: #e3cc86;
      margin: 62px auto;
      border-radius: 56px;
      min-width: 260px;
      padding: 12px 22px 32px;
      position: relative;
      border: 1px solid #735f24;

    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 56px;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
    }
    }
    .card {
      // width: 694px;
      background:#00000070 url("../assets/img/sbg.png") no-repeat ;      
      // background: #00000070;
      // background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
         background-size: 230px auto;
    background-position: right bottom;
          align-items: center;
          border: 1px solid #735f24;
      border-radius: 60px;
      // min-height: 300px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
      .tab{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 32px;
        .tabitem{
          width: 33%;
          height: 68px;
          font-size: 32px;
          color: #ffffff;
          position: relative;
        }
        .act{
          font-size: 38px;
      font-weight: bold;
      color: #e3cc86;
        }
        .act::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
    }
      }
      .bitem{
        width: 100%;
      }
      .box{
        width: 100%;
        .tips{
           font-size: 24px;
           color: #ffffff;
           margin-top: 40px;
          }
          .line{
            width: 100%;
            height: 1px ;
            background: #735f24;
            margin-top: 40px;
          }
          .m10{
            margin: 20px 0;
          }
          .bord{
                border: 1px solid #735f24;
                padding: 12px;
                border-radius: 16px;
          }
          .m10{
            margin-top: 36px;
          }
          .item{
            padding: 20px 36px;
            border-radius: 26px;
            border: 1px solid #735f24;
            margin-top: 12px;
          }
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
            font-size: 24px;
            color: #FFFFFF;
            opacity: 0.6;
          }
          .right{
            .sinput{
              width:auto;
              padding: 5px 40px;
              border-radius: 100px;
              height: 64px;
              background: #2A2A2A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              box-sizing: border-box;
              .txt{
                color: #717171;
                margin-right: 16px;
              }
              .num{
                color: #ffffff;
                font-size: 32px;
                font-weight: bold;
                margin-right: 16px;
              }
            }
          }
          .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 64px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0;       
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
              }
            }
          }
          .icos{
            font-size: 36px;
            color: #ffffff;
            .ico{
                width: 40px;
                height: 40px;
                border: 1px solid #ffff;
    border-radius: 100%;
              }
              .txt{
                font-size: 22px;
                color: #ffffff;
              }
          }
          .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 20px;
                // font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
            .fuzhi{
                 background: transparent;
               border: none;
            }
          }

         
          .ibox{
            border: 1px solid #735f24 ;
            border-radius: 26px;
            width: 100%;
            margin-top: 40px;
            padding: 30px 40px;
            box-sizing: border-box;
            background: linear-gradient(135.46deg, rgb(0 0 0) 24.8%, rgba(99, 99, 99, 0.5) 57.07%);
            // background:#00000070 url("../assets/img/sbg.png") no-repeat ; 
            box-shadow: 0 2px 22px 0 #000000;  
                     .time{
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            margin: 28px auto 0;
          }
            .txts{
              color: #ffffff;
              font-size: 24px;
              border: 1px solid #735f24;
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 32px;
              .num{
                font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #ffbe00 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 10px;
              }
            }
          }
          .btnr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .btn{
          background: #2A2A2A;
            color: #ABABAB;
            border: none;
            border-radius: 100px;
            height: 68px;
            width: 250px;
            font-size: 24px;
            font-weight: bold;
            margin-left: 10px;
          }
        .act{
          color: #ffffff;
          box-shadow:inset 0px 4px 16px 4px #735f24;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
        }
          }
          .btngroup{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .btns{
              background: #2A2A2A;
              color: #ABABAB;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #2A2A2A;
            }
            .btn{
              background: transparent;
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #735f24;
              margin-left: 20px;
            }
          }

        }
      }
      .buttom{
        display: flex;
        width: 100%;    
        margin-top: 30px;  
        .btn{
     
    background: #2A2A2A;
            color: #ABABAB;
            border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
          font-weight: bold;
          margin: 0 16px;
        }
        .btns{
          color: #ABABAB;
          width: 50%;
          font-size: 24px;
          font-weight: bold;
          margin: 0 16px;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          background: transparent;
          border: 1px solid #ae8e51;
        }
        .act{
          background: #000000;
          color: #e3cc86;
          border: 1px solid #ae8e51;
          // color: #ffffff;
          // border: 1px solid transparent;
          // box-shadow:inset 0px 4px 16px 4px #735f24;
          // background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);

        }
      }
        
       

       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      
        
      .sbot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
          .btn {
            background: #ffffff;
            // box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
            // border-radius: 40px;
            font-size: 32px;
            font-family: $t-f;
            font-weight: 600;
            color: #000000;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 280px;
height: 80px;
          }
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
 
   .nocard{
      margin: 20px 30px 40px;
          .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
      .box{
        width: 100%;       
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;          
         
          .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 20px;
                // font-weight: bold;
                margin:  0 10px;
            }
           
          }

         
          .ibox{
            border: 1px solid #735f24 ;
            border-radius: 60px;
            width: 100%;
            margin-top: 40px;
            padding: 30px 40px;
            box-sizing: border-box;
            // background: linear-gradient(135.46deg, rgb(0 0 0) 24.8%, rgba(99, 99, 99, 0.5) 57.07%);
            // background:#00000070 url("../assets/img/sbg.png") no-repeat ; 
            box-shadow: 0 2px 22px 0 #000000;  
            
            .txts{
              color: #ffffff;
              font-size: 24px;
              border: 1px solid #735f24;
               background: linear-gradient(135.46deg, rgb(0 0 0) 24.8%, rgba(99, 99, 99, 0.5) 57.07%);
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 32px;
              .num{
                font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #ffbe00 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 10px;
              }
            }
          }
           .line{
            width: 100%;
            height: 1px ;
            background: #735f24;
            margin-top: 40px;
          }
 
          .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 32px;
            .left{
              font-size: 26px;
              color:#ffffff;
              text-align: left;
            }
            .rbox{
              
            }
          }
        }
      }
   }
    .linebox{
      display: flex;
      align-items: center;
      justify-content: space-around;
      .card3{
        flex: 1;
        margin: 10px 28px;
        background:#00000070;//#262626;
        // border-radius: 20px;
            border: 1px solid #381b7c;
        // height: 360px;
            display: flex;
    align-items: center;
    justify-content: center;
         .list {
          flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin: 10px 0 10px;
        padding:20px 0;
        position: relative;
        .she{
          position: absolute;
          right: 10px;
          top: 10px;
          height: 45px;
          width: 45px;
        }
        .item {
          flex: 1;
          overflow: hidden;
          .ico{width: 68px;}
            .num {
        margin-top: 22px;
        font-size: 32px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%, #ffe69d 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
          .btn {
           min-width: 280px;         
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px 20px;
            font-weight: 600;
            color: #ffffff;
            margin: 10px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
             border: 1px solid #381b7c;
             background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
            .line{
              height: 20px;
              width: 1px;
              background: #b4184c;
              margin:0 20px;
            }
          }
        }
      }
      // .row{
      //   flex-direction: row;
      // }
      }
    }
    .card2 {
      width: 694px;
      height: 100%;
      background:#00000070;// #262626;
      // box-shadow: 0px 4px 16px 8px rgba(227, 224, 224, 0.5);
      // @include box_color("box_color");
          border: 1px solid #381b7c;
      // border-radius: 20px;
      margin: 28px auto;
      padding: 28px 0;
       color:#000;
       
      .ico{
        width: 43px;
        height: 43px;
      }
      .imgroup{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 48px;
        .img{width: 86px;
        height: auto;
          // border-radius: 10px;
        }
      }
      .mytg{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0 28px;
      .left{
        display: flex;
        align-items: center;
        .tt{
          margin: 0 10px;
        }
      }
      .right{
       
      }
      }



      .title {
        margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;       
        .node {
          font-size: 30px;
          font-weight: 600;
          color: #8B8B94;
          line-height: 32px;
        }
      }
      .lmtop{
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;
         color:#000000;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
           
          }
        }
        .cont{
          height: 76px;
          width: 76px;
        }
        .right{
           display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
          }
        }
      }
      .sinput{
        border-top: 1px dashed #e9e9e9;
        border-bottom:1px dashed #e9e9e9;
        display: flex;
        height: 100px;
        justify-content: space-between;
        align-items: center;
         padding: 10px 32px;
        .left{
          width: 50%;         
         input{
          border: none;
          text-align: left;
          font-family: $t-f;
          background: transparent;
        }
        }
        .right{
          width: 50%;
         input{
          border: none;
          text-align: right;
          font-family: $t-f;
          background: transparent;
        }
        }
      
      }
      .info{
        padding: 10px 32px;
        .tlt{
          font-size: 22px;
          margin: 20px 0;
        }
        .total{
          display: flex;
          align-items: center;
          justify-content: space-between;

        }
      }
      .tips {
        display: flex;
        align-items: center;
        flex-direction: column;

        .cnum {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #999999;
          line-height: 40px;
        }
      }

      .cbox{
            margin: 30px;
    padding: 14px 20px;
    position: relative;
    background: #fff;
    box-shadow: inset 0 -2px 8px 0 rgb(51 1 138 / 8%);
    // border-radius: 15px;
    border: 1px solid;
        .tl{
          font-weight: 600;
          font-family: $t-mf;
          font-size: 26px;
          text-align: left;
          color: #333;
        }
        .line{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 30px;
          font-family: $t-f;
          margin: 40px 10px;
          color:#00023d;
        }

      }

      .num {
        margin-top: 22px;
        font-size: 18px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
      .gree{
        color: #14f714;
      }
      .red{
        color: #ec2832;
      }
      .sitm{
    background: #313131;
    // border-radius: 10px;
    padding: 10px 0 20px;
    margin: 5px;

    .num{
      overflow: hidden;
      white-space: pre-wrap;
      padding: 0 0 0 10px;
    }
      }
      .bottom{
        margin-top: 64px;
         .btn {
            width: 80%;
            height: 80px;
            // background: #b4184c;
            
          background: #a2a2a9;
// box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
            font-size: 32px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 80px;
            margin: 20px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
         }
         .stbn{
            font-size: 30px;
            font-family: $t-f;
            font-weight: 600;
            color: #8B8B94;
            margin: 10px auto;
            line-height: 34px;
         }
      }
      .list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 24px 0;
        .item {
          // flex: 1;
          overflow: hidden;

          .btn {
            min-width: 100px;  
            max-width: 160px;          
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px ;
            font-weight: 600;
            color: #ffffff;
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
          }
        }
      }

      .jjc {
        line-height: 52px;
        display: flex;
        justify-content: center;
        span:nth-child(1) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #888888;
        }
        span:nth-child(2) {
          font-size: 44px;
          font-family: $t-f;
          font-weight: bold;
          @include font_color("font_color1");
          margin: 0 52px 0 20px;
          max-width: 50%;
          overflow-x: auto;
          display: inline-block;
          overflow-y: hidden;
        }
        span:nth-child(3) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
        }
      }
      .time {
        margin: 52px auto 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 42px;
          width: 38px;
          margin-right: 12px;
        }
        div {
          font-size: 44px;
          font-family: $t-mf;
          font-weight: bold;
          @include font_color("font_color1");
          line-height: 52px;
        }
      }

      .txt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color: #616161;
        line-height: 40px;
      }

      .bltitle {
        height: 60px;
        @include background_color("background_color5");
        font-size: 32px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color1");
        line-height: 60px;
        margin-top: 69px;
      }
      .list {
        .listtop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
          line-height: 40px;
          padding: 0 52px;
          margin: 25px auto;
          .sort {
            width: 56px;
          }
        }
        .items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 52px;
          margin: 25px auto;
          .sord {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
            width: 56px;
            text-align: center;
          }
          .address {
            span {
              height: 32px;
              font-size: 28px;
              font-family: $t-f;
              font-weight: bold;
              @include font_color("font_color1");
              line-height: 32px;
              margin: 0 13px;
            }
            .fuzhi {
              background: transparent;
              border: none;
              width: 30px;
            }
            img {
              height: 30px;
              width: 28px;
            }
          }
          .num {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
          }
        }
      }
      .buttombtn {
        margin: 15px auto 60px;
        width: 152px;
        height: 54px;
        background: #f3f3f3;
        // border-radius: 27px;
        transform: all 0.3;
        span {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #aaaaaa;
          line-height: 54px;
          margin: 0 10px;
        }
        img {
          width: 22px;
          height: 14px;
        }
        .sxz {
          transform: rotate(180deg);
        }
      }
    }
    .sbfg{
    //   border: 1px solid #381b7c;
    // background: url("../assets/img/abg.png") 100%/100% no-repeat;
    
background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
        display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    .btn{
      display: flex;
      align-items: center;
      justify-content: flex-end;
        margin:  0 20px;
        min-width: 252px;
        height: 84px;
        line-height: 84px;
        //  background: linear-gradient(18deg, #ffdf2d, #00e2be);
        border: 1px solid #ffffff; 
        // border-radius: 70px;
        padding-left: 20px;
        font-weight: 600;
        font-family: $t-mf;
        overflow: hidden;
        
        .she{
          height: 45px;
          width: 45px;
          margin: 0 30px;
        }
        .tnxt{
          color: #ffffff;
        font-family: $t-mf;
        white-space: nowrap;
             font-size: 36px;
        }
    }
  }
   .card4 {
    width: 652px;
    // background: linear-gradient(18deg, #ffdf2d, #00e2be);
    // border-radius: 20px;
    background: #00000070;
    padding: 20px;
    margin: 20px auto;
border: 1px solid #381b7c;
    .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      .ltxt {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
      }
      .mylink {
        width: 440px;
        height: 60px;
        background: #f8f8f8;
        // border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 250px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .tips {
      font-size: 24px;
      font-family: $t-mf;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      margin: 22px 0 0;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      // border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }
    .more {
      width: 750px;
      margin: 0 auto;
      .txts {
        height: 56px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
       color: #fff;
        line-height: 56px;
        margin: 54px auto;
      }
     .qblist {
        display: flex;
        flex-wrap: wrap;
        margin: 15px;
        .icons {
          width: calc(50% - 32px);
          background: #262626;
          // box-shadow: 0px 4px 16px 8px rgba(227,224,224,0.5);
          // border-radius: 7px;
          // border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          margin: 16px;
          img {
            width: 84px;
            height: 84px;            
            padding: 16px;
          }
          span {
            height: 44px;
            font-size: 22px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 44px;
            cursor: pointer;
            flex: 1;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
    .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 10px;
      padding-bottom: 10px;
      
    }
  }
 

  .popup {
    width: 672px;
    background: #ffffff;
    // border-radius: 30px;
        padding: 30px 30px 40px;
        box-sizing: border-box;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    ::v-deep .van-stepper__input{
          font-size: 42px;
        }
   
    .tips{
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 600;
      color: #999999;
      line-height: 66px;
      margin: 24px auto;
    }
    .inode {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background: #f1f1f1;
      // border-radius: 8px;
      margin: 8px 34px;
      padding: 20px 8px;
      font-weight: 400;
      font-size: 28px;
      font-family: $t-f;
      .items{
         display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px ;
        flex: 1;
        .left {
        text-align: left;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 400;
          color: #999;
          line-height: 32px;
      }
      .right {
        font-size: 22px;
        font-family: $t-f;
        font-weight: 600;
        color: #131415;
        line-height: 32px;
      }
      }}
      .fuzhi {
        background: transparent;
        border: none;
        color: #1755ee;
      }
    }
    .input {
      border: none;
      width: 70%;
      height: 50px;
      background: #f8f8f8;
      // border-radius: 50px;
      margin: 10px auto;
      padding: 10px 20px;
      font-size: 28px;
      // background: transparent;
      
    }
    .bg{
       background: linear-gradient(234deg,#e4005e, #b4184c);
        margin: 20px 34px;
       padding:10px;
       
    // border-radius: 20px;
       
    }
    .logo{
      // height:188px;
      width: 188px;
      margin: 44px auto 20px;
    }
    .item {
     display: flex;
     align-items: center;
     justify-content: space-between;
       background:#262626;
      //  border-radius: 10px;
    padding: 0 10px;
      .txts {
        font-size: 32px;
        font-family: $t-f;
        text-align: right;
        margin: 10px 0;
    font-weight: bold;
    line-height: 54px;
     background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .flexs{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
      
        .tts{
           font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 40px;
          text-align: left;
          margin: 0 10px ;
          
        }
      }
      .txd{
        margin: 20px 0;
        text-align: left;
        font-size: 24px;
        font-family: $t-f;
        font-weight: 600;
        color: #f8f8f8;
        line-height: 40px;
      }
      .inputs {
        border: none;
        height: 50px;
        width: 100%;
        font-size: 34px;
        font-family: $t-f;
      }
    }
    .txt {
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 400;
      @include font_color("font_color1");
      line-height: 40px;
      margin: 10px 106px;
      text-align: left;
    }
      .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;
            }
             .title{
            width: 100%;
            font-size:32px;
            font-weight: bold;
            color: #000000;
            text-align: left;
           
        }
        }
         .sqtxts{
      font-size: 24px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
      text-align: left;
    }
    .sqtxt{
      font-size: 32px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
    }
    .xbox{
       background: #F3F0F4;
            padding: 10px 20px;
            border-radius: 36px;
             display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px auto;
              .num{
          //  margin: 22px auto;
        font-size: 24px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(34deg, #1fa391, #28514b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
    }
    .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
           
            .num{
           //  margin: 22px auto;
                font-size: 42px;
                font-family: $t-f;
                font-weight: bold;
                  background: linear-gradient(234deg, #106156, #cc1d1d);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
            
          }
          .sinput{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            background: #efefef;
            border-radius: 10px;
            height: 84px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px auto; 
            .laber{
              color: #a7841e;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
                padding-right: 16px;
                border-right: 1px solid #a7841e;
            }      
            .input{
              border: none;
              background: transparent;
              color: #a7841e;
              font-size: 28px;
              font-weight: bold;
              flex: 1;
            }
           
          }
    .flsz{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .cnet{
      text-align: center;
      font-size: 46px;
    }
    .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
     .btns {
      width: 480px;
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .nbtn{
      width: 100%;
      height: 80px;
box-shadow: 0px 4px 16px 4px rgba(227,211,253,1);
// border-radius: 40px;
border: 2px solid rgba(115, 40, 236, 0.5);
font-size: 32px;
font-family: $t-f;
margin: 54px 20px;
font-weight: 600;
color: #b4184c;
line-height: 44px;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .lang {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      img {
        height: 50px;
        width: 50px;
      }
      span {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 400;
        @include font_color("font_color1");
        line-height: 40px;
      }
    }
 
  }
 
  .flexbottom{
    position: fixed;
    bottom: 0;
    width: 750px;
    height: 160px;
    background: linear-gradient(18deg, #ffdf2d, #00e2be);
    z-index: 99;
    // border-radius: 50% 50% 0 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .txt{
    font-size: 28px;
    font-weight: 600;
    font-family: $t-f;
    line-height: 42px;
    }
  }
   .infos{
        font-size: 28px;
        font-weight: 600;
        font-family: $t-mf;
        text-align: justify;
        background: #000000;
        display: flex;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
      .video{
        width: 100%;
        height: 100vh;
      }
      }
}
</style>
