export default {
    // 创建钱包
    createWallet:'wallet/createWallet',
    // 查询钱包
    getWallet:"wallet/getWallet",
    // 大区某轮列表
    getRegionHistoryList:"region-history/getRegionHistoryList",
    // 加入游戏
    joinRound:'region-history/joinRound',
    // 大区列表
    getRegionList:"region/getRegionList",
    // 大区详情
    getRegionDetail:"region/getRegionDetail",
    // 用户大区列表
    getUserRegionList :"region/getUserRegionList",
    // 用户大区详情
    getUserRegion:"region/getUserRegion",
    //资产明细
    getBalanceDetailList:"wallet/getBalanceDetailList",
    // 提现
    getReward:"wallet/getReward",
    // 充值
    recharge:"wallet/recharge",
    // base
    getBase:"sys-base/getBase",
    // 提现记录
    getHisList:"wallet/getHisList",
    // 充值记录
    getRechargeList:"wallet/getRechargeList",
    // 消息列表
    getNewsList:"mms-news/getList",
    // 消息详情
    getOne:"mms-news/getOne",
    // 累计数据
    getTotal:"stats/getTotal",
    // 兑换NFT
    exchangeNFT:"wallet/exchangeNFT"
    





}